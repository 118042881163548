/**
 * Environment for deploying edge version. This environment auto-deploys each change pushed to develop branch.
 * Releases are made for last commit of each git push.
 *
 * ??? This environment communicates with dev/test version of other integrations. (Eg: a HUPX trade is sent to test HUPX system.)
 */
export const environment = {
  enableAngularProdMode: true,
  reportErrors: false,
  environment: 'development',
  frontendVersion: '0.65.0+changes (from develop at ad8c04b)',
  /** The development and test stage shares the same app registration. */
  azureADTenantID: '94fa3ab9-f544-42c1-983e-d6d2a751ef5e',
  azureADClientID: '0fe05e8f-0250-4dee-8f17-ab038cdd06c6',
  azureADApiAppURI: 'api://alteo-sinergy-platform-dev-api',
  apiBasePath: 'https://api.platform.dev.artemis-energy.com',
  pagination: {
    defaultPageLimit: 50,
  },
};
